<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item v-bind="$attrs" :to="to" @click="$emit('click')">
      <v-list-item-avatar v-if="showAvatar" :color="avatarColor">
        <v-img
          v-if="useAvatarImage"
          :src="image"
          width="30"
          height="30"
          max-width="30"
          max-height="30"
        />
        <v-icon v-else :color="iconColorComputed">{{ icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <template v-if="!invert">
          <v-list-item-title v-if="title">{{ title }}</v-list-item-title>
          <v-list-item-subtitle v-if="subTitle">{{
            subTitle
          }}</v-list-item-subtitle>
        </template>
        <template v-else>
          <v-list-item-subtitle v-if="title">{{ title }}</v-list-item-subtitle>
          <v-list-item-title v-if="subTitle">{{ subTitle }}</v-list-item-title>
        </template>
      </v-list-item-content>
      <v-list-item-action v-show="action && hover">
        <v-btn text icon @click="action">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  name: 'ListItemInfo',
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    subTitle: {
      type: [String, Number],
      default: ''
    },
    invert: {
      type: Boolean,
      default: false
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    avatarColor: {
      type: String,
      default: ''
    },
    useAvatarImage: {
      type: Boolean,
      default: false
    },
    hardLinked: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: require('@/assets/Icons/car.svg')
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: [String, undefined],
      default: undefined
    },
    actions: {
      type: Array,
      default: () => []
    },
    action: {
      type: Function,
      default: () => ({})
    },
    to: {
      type: [String, Object],
      default: ''
    }
  },
  computed: {
    iconColorComputed() {
      return this.iconColor || (this.avatarColor ? 'white' : undefined);
    }
  }
};
</script>
